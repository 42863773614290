import AdminNavigation from "components/navigation/AdminNavigation";
import AccountNavigation from "components/navigation/AccountsNavigation";

import * as roleUtils from "utils/roleUtils";
import * as utils from "utils/utils";
import * as authUtils from "utils/authUtils";

import { Fragment, lazy, Suspense, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import config from "config";

import { withRouter } from "react-router-dom";

// Routes management
const Routes = lazy(() => import("../Routes"));
import {
  Button,
  generalUtils,
  Loader,
  NewVersionAvailable,
  withStore
} from "bob-group-ui-framework";
import { withErrorBoundary } from "errorBoundary";
import { IAccount } from "interfaces/account.interface";

interface IProps {
  onNavSectionReady: any;
  history: any;
  token: any;
  store: any;
  activeUser: any;
  userHasAuthenticated: boolean;
  showNav: boolean;
  isNewVersionAvailable: boolean;
  isNavbarCollapsed: boolean;
  setNavbarCollapsed: any;
  version: string;
  isDesktop: boolean;
  onStopImpersonating?: () => void;
  theme: string;
}

function PageLayout(props: IProps) {
  const {
    isNavbarCollapsed,
    showNav,
    isNewVersionAvailable,
    version,
    store,
    history,
    activeUser,
    token,
    userHasAuthenticated,
    theme
  } = props;

  const activeAccount: IAccount = store.account;
  const hasBobBoxTheme = utils.hasBobBoxTheme(activeUser);

  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [isOffline, setIsOffline] = useState<boolean>(false);
  const [shouldShowBackOnline, setShouldShowBackOnline] = useState<boolean>(false);

  window.addEventListener("online", () => {
    setShouldShowBackOnline(true);
    setTimeout(() => {
      setShouldShowBackOnline(false);
      setIsOffline(false);
    }, 2000);
  });
  window.addEventListener("offline", () => setIsOffline(true));

  function toggleNavbarCollapsed() {
    props.setNavbarCollapsed(!isNavbarCollapsed);
    localStorage.setItem("is_navbar_collapsed", JSON.stringify(!isNavbarCollapsed));
  }

  function isLoginScreen() {
    return (
      window.location.pathname.indexOf("/login") >= 0 ||
      window.location.pathname.indexOf("/register") >= 0
    );
  }

  /* -------------------------------- */
  /* RENDER METHODS */
  /* -------------------------------- */

  function renderOfflineNotification() {
    if (!isOffline) {
      return null;
    }

    return (
      <div
        className={`${
          shouldShowBackOnline ? "bg-green-light" : "bg-red-light"
        } p-4 flex justify-between items-center ${
          shouldShowBackOnline ? "text-green" : "text-red"
        } font-bold sticky top-0 z-20 -mt-6 mb-4 -mx-4`}
      >
        <div className="flex items-center">
          <FontAwesomeIcon
            icon={shouldShowBackOnline ? "check" : "exclamation-circle"}
            className={`mr-4 ${isLoginScreen() ? "ml-4" : ""}`}
          />
          <div className="link-container">
            {shouldShowBackOnline
              ? "You are back online!"
              : "You are currently offline. Please check your internet connection."}
          </div>
        </div>
      </div>
    );
  }

  function renderImpersonation() {
    if (!store || !store.impersonated_user_id || !store.logged_in_user) {
      return null;
    }

    return (
      <div className="bg-orange-light p-4 flex justify-between items-center text-orange font-bold sticky top-0 -mt-6 mb-4 -mx-4 z-50">
        <div>
          <FontAwesomeIcon icon="rocket" className="mr-4" />
          Impersonating {store.logged_in_user.name} ({store.logged_in_user.email}){" "}
        </div>
        <FontAwesomeIcon
          id="stop_impersonating"
          icon="times"
          className="cursor-pointer"
          onClick={() => {
            utils.stopImpersonating(store);
            if (props.onStopImpersonating) {
              props.onStopImpersonating();
            }
          }}
        />
      </div>
    );
  }

  function renderNavExpandCollapseButton() {
    if (!roleUtils.userHasAdminSystemAccess(props) || !activeUser || !showNav) return null;

    return (
      <div
        onClick={() => {
          toggleNavbarCollapsed();
        }}
        className={
          "cursor-pointer " +
          (hasBobBoxTheme ? "text-gray hover:text-gray-dark" : "text-white hover:text-gray-light")
        }
        title={isNavbarCollapsed && !isSidebarOpen ? "Expand navigation" : "Collapse navigation"}
      >
        <FontAwesomeIcon
          icon={isNavbarCollapsed && !isSidebarOpen ? "angle-double-right" : "angle-double-left"}
        />
      </div>
    );
  }

  function renderLogo(sectionName: string, excludeMargin?: boolean) {
    const isBobBoxCounterOperatorUser =
      activeUser && roleUtils.isBobBoxCounterOperatorUser(activeUser.role.name);
    const isBobBoxAdminOrOpsUser =
      activeUser &&
      (roleUtils.isBobBoxCustomerSupportUser(activeUser.role.name) ||
        roleUtils.isBobBoxLockerMaintenanceUser(activeUser.role.name) ||
        roleUtils.isBobBoxAdminUser(activeUser.role.name) ||
        roleUtils.isBobBoxHardwareAdminUser(activeUser.role.name));

    let badge = utils.isProd() ? "" : utils.getEnvironment(config.api);
    if (isBobBoxCounterOperatorUser || utils.isBobBoxCounterDomain()) {
      badge = `${badge ? badge + "-" : ""}counter`;
    } else if (isBobBoxAdminOrOpsUser || utils.isBobBoxManageDomain()) {
      badge = `${badge ? badge + "-" : ""}manage`;
    }

    return (
      <div className="relative w-full">
        {badge && (
          <div
            className={
              "items-center px-2 py-0.5 mt-1 bg-primary rounded-full text-xs text-white absolute -top-3 " +
              (isNavbarCollapsed && !isSidebarOpen ? "right-1" : "-right-2")
            }
          >
            {badge}
          </div>
        )}
        <img
          onClick={() => history.replace("/")}
          onLoad={() => {
            props.onNavSectionReady(sectionName);
          }}
          className={
            (badge ? "mt-2" : "mt-0") +
            " h-10 w-auto cursor-pointer " +
            (excludeMargin ? "align-center" : "mx-auto " + (badge ? "mb-2" : "mb-4")) +
            (isNavbarCollapsed && !isSidebarOpen ? (!utils.isProd() ? "px-2 pt-4" : "px-2") : "")
          }
          alt="Logo"
          src={
            process.env.PUBLIC_URL +
            (isNavbarCollapsed && !isSidebarOpen
              ? `/${hasBobBoxTheme ? "bobbox_" : ""}favicon.png`
              : `/${hasBobBoxTheme ? "bobbox_logo_black.png" : "logo_white.png"}`)
          }
        />
      </div>
    );
  }

  function renderSystemDetails() {
    const { provider_settings } = store;

    return (
      <div style={{ display: showNav ? undefined : "none" }}>
        {!utils.isMaintenanceModePage() && !utils.isAccountClosedPage() && (
          <div>
            {!isNavbarCollapsed && (
              <div className={"mt-4 " + (hasBobBoxTheme ? "text-gray" : "text-white")}>
                {isNewVersionAvailable && <NewVersionAvailable />}

                {provider_settings && provider_settings["terms_url"] && (
                  <Button.Link
                    isSmall
                    to={provider_settings["terms_url"]}
                    target="_blank"
                    title="Terms and conditions"
                  />
                )}

                {provider_settings && provider_settings["privacy_url"] && (
                  <Button.Link
                    isSmall
                    to={provider_settings["privacy_url"]}
                    target="_blank"
                    title="Privacy policy"
                  />
                )}
                <div className="px-3 pt-2 text-xs">{version}</div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  function renderNavigation() {
    return roleUtils.userHasAdminSystemAccess(props) ? (
      <AdminNavigation
        onMounted={() => {
          props.onNavSectionReady("navItems");
        }}
        toggleNavbarCollapsed={toggleNavbarCollapsed}
        isNavbarCollapsed={isNavbarCollapsed}
        activeUser={activeUser}
        closeMenu={() => setIsSidebarOpen(false)}
        hasBobBoxTheme={hasBobBoxTheme}
      />
    ) : (
      <AccountNavigation
        onMounted={() => {
          props.onNavSectionReady("navItems");
        }}
        toggleNavbarCollapsed={toggleNavbarCollapsed}
        isNavbarCollapsed={isNavbarCollapsed}
        activeUser={activeUser}
        closeMenu={() => setIsSidebarOpen(false)}
        hasBobBoxTheme={hasBobBoxTheme}
      />
    );
  }

  function renderProfile() {
    if (!activeUser) return null;

    const roleTitle =
      activeAccount?.account_code && activeAccount?.name && !roleUtils.isSuperAdmin(activeUser)
        ? activeAccount.account_code + " – " + activeAccount.name
        : activeUser?.role?.name;

    if (isNavbarCollapsed) {
      return (
        <div className="py-2 cursor-pointer text-center">
          <FontAwesomeIcon
            icon="user-circle"
            className={"text-2xl " + (hasBobBoxTheme ? "text-gray" : "text-white")}
            onClick={() => {
              history.push("/profile");
            }}
          />
          <div className="mt-3">{!isSidebarOpen && renderNavExpandCollapseButton()}</div>
        </div>
      );
    }

    return (
      <div className="flex border-t border-gray-light py-2 px-3">
        <div className="flex w-full items-center">
          <div
            className="cursor-pointer"
            onClick={() => {
              history.push("/profile");
            }}
          >
            <FontAwesomeIcon
              icon="user-circle"
              className={"text-4xl " + (hasBobBoxTheme ? "text-gray" : "text-white")}
            />
          </div>
          <div className="ml-3">
            <p
              className={"font-semibold " + (hasBobBoxTheme ? "text-gray" : "text-white")}
              onClick={() => {
                history.push("/profile");
              }}
            >
              {generalUtils.shorten(activeUser.name, 18)}
            </p>
            {(activeAccount || activeUser?.role?.name) && (
              <p
                title={roleTitle}
                className={
                  "text-xs truncate w-60 md:w-36 " + (hasBobBoxTheme ? "text-gray" : "text-white")
                }
              >
                {roleTitle}
              </p>
            )}
            <p
              className={
                "text-xs font-semibold  cursor-pointer mt-1 " +
                (hasBobBoxTheme
                  ? "text-gray hover:text-gray-dark"
                  : "text-primary-light hover:text-white")
              }
              onClick={() => authUtils.logOut(store)}
            >
              Log out
            </p>
          </div>
          <div className="ml-auto mt-2">{!isSidebarOpen && renderNavExpandCollapseButton()}</div>
        </div>
      </div>
    );
  }

  function render() {
    const childProps = {
      userHasAuthenticated: userHasAuthenticated,
      token: token,
      store: store
    };
    return (
      <div className="h-screen flex overflow-hidden bg-gray-lightest">
        <Transition.Root show={isSidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed inset-0 flex z-40 md:hidden"
            open={isSidebarOpen}
            onClose={setIsSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div
                data-theme={theme}
                className={`relative flex-1 flex flex-col max-w-60 w-full ${
                  hasBobBoxTheme ? "bg-white" : "bg-primary"
                }`}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none"
                      onClick={() => setIsSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <FontAwesomeIcon icon="times" className="text-white" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                  <div className="flex-shrink-0 flex items-center px-4">
                    {renderLogo("mobileLogo")}
                  </div>
                  <nav className="mt-2 px-2">
                    <div className="z-20">{renderNavigation()}</div>
                  </nav>
                </div>
                {renderProfile()}
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div
          className={`hidden md:flex md:flex-shrink-0 bg-no-repeat bg-bottom ${
            hasBobBoxTheme ? "bg-white" : "bg-primary"
          }`}
          style={{
            backgroundImage: "url(" + process.env.PUBLIC_URL + "/left_nav_pattern.png" + ")"
          }}
        >
          <div
            className={"flex flex-col " + (isNavbarCollapsed && !isSidebarOpen ? "w-12" : "w-60")}
          >
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex flex-col h-0 flex-1">
              <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                <div
                  className={
                    "flex items-center flex-shrink-0 " +
                    (isNavbarCollapsed && !isSidebarOpen ? "" : "px-4")
                  }
                >
                  {renderLogo("desktopLogo")}
                </div>
                <nav className="flex-1 px-2">
                  {renderNavigation()}
                  {renderSystemDetails()}
                </nav>
              </div>

              {renderProfile()}
            </div>
          </div>
        </div>

        <div className="flex flex-col w-0 flex-1 overflow-hidden">
          <div
            className={`md:hidden pl-1 border-b border-gray-lighter flex fixed z-10 w-full pt-2 ${
              hasBobBoxTheme ? "bg-white" : "bg-primary"
            }`}
          >
            <button
              className={`-ml-0.5 -mt-0.5 mb-2 h-12 w-12 inline-flex items-center justify-center rounded-md hover:text-gray-light ${
                hasBobBoxTheme ? "text-gray" : "text-white"
              } focus:outline-none`}
              onClick={() => setIsSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <FontAwesomeIcon icon="bars" size="xl" />
            </button>
            <div className="items-center justify-center flex">{renderLogo("mobileLogo", true)}</div>
          </div>
          <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none mt-4 sm:mt-0">
            <div className={isLoginScreen() ? "" : "pt-16 md:pt-6 pb-4"}>
              <div className={"mx-auto " + (isLoginScreen() ? "" : "px-4")}>
                {renderOfflineNotification()}
                {renderImpersonation()}
                <Suspense fallback={<Loader.Page />}>
                  <Routes childProps={childProps} />
                </Suspense>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }

  return render();
}

export default withStore(withRouter(withErrorBoundary(PageLayout)));
